import axios from 'axios';
import AuthAxiosInstance from '../../common/axios_config/AuthAxiosInstance';
import AxiosInstance from '../../common/axios_config/AxiosInstance';
import AxiosLogin from '../../common/axios_config/AxiosLogin';
import CommonAxiosInstance from '../../common/axios_config/CommonAxiosInstance';
import CommonNoAuthAxiosInstance from '../../common/axios_config/CommonNoAuthAxiosInstance';
import url_parameter_encryption from '../../common/url_parameter_encryption/url_parameter_encryption';

export const login_service = async (user_email, user_password) => {
    var return_data = null;

    await AxiosLogin({
        method: "Post",
        errorHandle: false,
        url: "Login/Login",
        data: {
            UserWorkingEmail: user_email,
            UserPassword: user_password
        }
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const logout_service = async () => {
    var return_data = [];

    await AuthAxiosInstance({
        method: "post",
        url: "Token/EndevoRevokeToken",
        data: {
            accessToken: sessionStorage.getItem("endevo_accessToken"),
            refreshToken: sessionStorage.getItem("endevo_refreshToken")
        }
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const getLanguage_service = async () => {
    var return_data = [];

    await CommonNoAuthAxiosInstance({
        method: 'POST',
        url: 'SYS_Language/GetAll',
        withCredentials: true,
        errorHandler: true
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        });

    return return_data;
};

export const updateLanguage_service = async (in_languageID, in_languageKey, in_language_us, in_language_my, in_language_cn, user_latitude, user_longitude) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'POST',
        url: 'sp_SYS_Language/Update',
        withCredentials: true,
        errorHandler: true,
        data: {
            LanguageID: in_languageID,
            LanguageKey: in_languageKey,
            LanguageEN: in_language_us,
            LanguageMY: in_language_my,
            LanguageCN: in_language_cn,
            Latitude: user_latitude,
            Longitude: user_longitude
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        });

    return return_data;
};

export const updateFormField_service = async (jsonData, txt_latitude, txt_longitude) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'POST',
        url: 'sp_SYS_Form_Management/UpdateFormField',
        errorHandler: true,
        data: {
            JsonData: jsonData,
            Latitude: txt_latitude,
            Longitude: txt_longitude
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        });

    return return_data;
};

export const getFormFieldListing_service = async (in_FormName, in_ScreenID) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'POST',
        url: 'sp_SYS_Form_Management/GetFormFieldListing',
        errorHandler: true,
        data: {
            FormName: in_FormName,
            ScreenID: in_ScreenID
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        });

    return return_data;
};

export const getAccessRights_service = async () => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'POST',
        url: 'ScreenAccessRights/GetUserScreenAccessRightsList',
        errorHandler: true,
        data: {
            ID: sessionStorage.getItem('endevo_userID')
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response;
            }
        });

    return return_data;
};

export const validateLicense_service = async () => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "Post",
        errorHandle: false,
        url: "SYS_Company/ValidateLicense"
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    })

    return return_data;
};

export const bindLicense_service = async () => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "Get",
        url: "SYS_Company/SelectLicenseInfo"
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const updateLicense_service = async (unlockKey) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "Post",
        url: "SYS_Company/UpdateLicense",
        errorHandle: false,
        data: {
            companyLicense: unlockKey
        }
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const userChangePassword_service = async (old_user_password, user_password, latitude, longitude) => {
    var new_rows = "";

    await CommonAxiosInstance({
        method: 'post',
        url: 'sp_User_User/UserChangePassword',
        errorHandler: true,
        data: {
            OldUserPassword: old_user_password,
            UserPassword: user_password,
            Latitude: latitude,
            Longitude: longitude
        }
    })
        .then(response => {
            if (response != null) {
                new_rows = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return new_rows;
};

export const getUserProfileData_service = async () => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'post',
        url: 'sp_User_User/GetOwnProfileDetail',
        errorHandler: true
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return return_data;
}

export const userUpdateProfile_service = async (txt_user_employee_number, txt_user_name, txt_user_nickname, txt_personal_email_address, txt_mobile_number, txt_home_phone_number,
    txt_user_user_position_code, txt_user_user_position_name, txt_user_user_company_name, txt_user_user_location, files, ddl_status, txt_latitude, txt_longitude
) => {
    var return_data = "";

    await CommonAxiosInstance({
        method: 'post',
        url: 'sp_User_User/UpdateByUser',
        errorHandler: true,
        data: {
            UserID: "00000000-0000-0000-0000-000000000000",
            UserEmployeeNumber: txt_user_employee_number,
            UserName: txt_user_name,
            UserNickName: txt_user_nickname,
            UserPersonalEmail: txt_personal_email_address,
            UserMobileNumber: txt_mobile_number,
            UserHousePhoneNumber: txt_home_phone_number,
            UserPositionCode: txt_user_user_position_code,
            UserPositionName: txt_user_user_position_name,
            UserCompanyName: txt_user_user_company_name,
            UserLocation: txt_user_user_location,
            AttachmentFile: files,
            StatusID: ddl_status,
            Latitude: txt_latitude,
            Longitude: txt_longitude,
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return return_data;
}

export const getAllUserByModule_service = async (in_module) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: 'post',
        url: 'sp_User_User/GetAllUserByModule',
        errorHandler: true,
        data: {
            Module: in_module
        }
    })
        .then(response => {
            if (response != null) {
                return_data = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return return_data;
}

export const refreshToken_service = async () => {
    await axios({
        method: "post",
        url: process.env.REACT_APP_AUTH_API_URL + "Token/RefreshToken",
        data: {
            userID: window.sessionStorage.getItem("endevo_userID"),
            refreshToken: window.sessionStorage.getItem("endevo_refreshToken")
        }
    }).then(response => {
        if (response != null) {
            if (response.data !== "error") {
                window.sessionStorage.setItem('endevo_accessToken', response.data.accessToken);
                window.sessionStorage.setItem('endevo_refreshToken', response.data.refreshToken);

                //Change Authentication API Authorization
                AuthAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                //Change API Authorization
                AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                //Common API Authorization
                CommonAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
            }
        }
    })
}

export const updateRefreshToken_service = async () => {
    await AuthAxiosInstance({
        method: "post",
        url: "sp_Token/UpdateAuthenticationToken",
        data: {
            accessToken: window.sessionStorage.getItem("endevo_accessToken"),
            refreshToken: window.sessionStorage.getItem("endevo_refreshToken")
        }
    }).then(response => {
        if (response != null) {
            var error_page_parameter = "";
            var error_url = "/404";

            if (response.data == false) {
                error_page_parameter = "errorcode=" + "500" + "&errormessage=" + "Server Error";
                window.location = url_parameter_encryption(error_url, error_page_parameter);
            }
        }
    });
}

export const getFullDashboardListing_service = async () => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "post",
        url: "sp_SYS_Dashboard_Management/GetKRIFullDashboardListing"
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const insertUpdateDashboardSettingAsAdmin_service = async (jsonData, user_latitude, user_longitude) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "post",
        url: "sp_SYS_Dashboard_Management/InsertUpdateKRIDashboardSettingAsAdmin",
        data: {
            JsonData: jsonData,
            Latitude: user_latitude,
            Longitude: user_longitude
        }
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};

export const insertUpdateDashboardSetting_service = async (jsonData, user_latitude, user_longitude) => {
    var return_data = [];

    await CommonAxiosInstance({
        method: "post",
        url: "sp_SYS_Dashboard_Management/InsertUpdateKRIDashboardSetting",
        data: {
            JsonData: jsonData,
            Latitude: user_latitude,
            Longitude: user_longitude
        }
    }).then(response => {
        if (response != null) {
            return_data = response.data;
        }
    });

    return return_data;
};