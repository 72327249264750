import Dexie from 'dexie';

const dashboard_indexedDB = new Dexie('Endevo_Dashboard');

// Declare tables, IDs and indexes
dashboard_indexedDB.version(1).stores({
    Dashboard: 'sysDashboardSettingsTableID,dashboardManagementID,applicationID,dashboardLanguageID,dashboardLanguageKey,dashboardManagementSequence,dashboardManagementTypeID,dashboardTypeID,dashboardTypeLanguageID,dashboardTypeLanguageKey,dashboardTypeSequence,isShow,dashboardisSystemMandatory,dashboardisMandatory,userID,dashboardisShow'
});

export default dashboard_indexedDB;
