import axios from 'axios';
import toast from './Toast';

import AuthAxiosInstance from '../axios_config/AuthAxiosInstance';
import AxiosInstance from '../axios_config/AxiosInstance';
import CommonAxiosInstance from '../axios_config/AxiosInstance';

import url_parameter_encryption from '../url_parameter_encryption/url_parameter_encryption';
import updateRefreshToken from '../refresh_token/update_refresh_token';

import Endevo_indexedDB from '../../common/indexedDB/indexedDB';
import access_rights_indexedDB from '../../common/indexedDB/access_rights_indexedDB';
import dashboard_indexedDB from '../../common/indexedDB/dashboard_indexedDB';

var ErrorMessage = "";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })
    failedQueue = [];
}

function errorResponseHandler(error) {
    const originalRequest = error.config;

    // check for errorHandle config
    if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
        return Promise.reject(error);
    }

    if (error.response) {
        //refresh token when hit 401
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return err
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function (resolve, reject) {
                axios({
                    method: "post",
                    url: process.env.REACT_APP_AUTH_API_URL + "Token/RefreshToken",
                    data: {
                        userID: window.sessionStorage.getItem("endevo_userID"),
                        refreshToken: sessionStorage.getItem("endevo_refreshToken")
                    },
                    headers: {
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem('endevo_accessToken')
                    }
                }).then(response => {

                    if (response.data !== "error") {
                        sessionStorage.setItem('endevo_accessToken', response.data.accessToken);
                        sessionStorage.setItem('endevo_refreshToken', response.data.refreshToken);
                        
                        //Change Authentication API Authorization
                        AuthAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                        //Change API Authorization
                        AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                        //Common API Authorization
                        CommonAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;

                        originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;

                        processQueue(null, response.data.accessToken);
                        resolve(axios(originalRequest));
                    }

                    else {
                        errorMessageHandle(error);
                    }
                })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .then(() => { isRefreshing = false })
            })

            return Promise.reject(error);

        } else {
            errorMessageHandle(error)
        }
    }
}

//handle error message 
function errorMessageHandle(error) {
    // if has response show the error
    if (error.response) {
        var error_page_parameter = "";
        var error_url = process.env.REACT_APP_SUBDIRECTORY + "/404"

        if (error.response.data != '' || error.response.data != undefined) {
            ErrorMessage = error.response.data.message;
            error_page_parameter = "errorcode=" + error.response.status + "&errormessage=" + ErrorMessage + "&referenceID=" + error.response.data.referenceID;

            if (ErrorMessage == "Duplicate Login") {
                sessionStorage.clear();

                Endevo_indexedDB.Language.clear();
                access_rights_indexedDB.AccessRights.clear();
                dashboard_indexedDB.AccessRights.clear();
            }

            window.location = url_parameter_encryption(error_url, error_page_parameter);
            toast.error(ErrorMessage, 'Reference ID:' + error.response.data.referenceID + '</br>' + 'Error Code:' + error.response.status);
        } else {
            //Setup Generic Response Messages
            if (error.response.status === 401) {
                ErrorMessage = 'UnAuthorized'
            } else if (error.response.status === 404) {
                ErrorMessage = 'API Route is Missing or Undefined'
            } else if (error.response.status === 405) {
                ErrorMessage = 'API Route Method Not Allowed'
            } else if (error.response.status === 422) {
                //Validation Message
            } else if (error.response.status >= 500) {
                ErrorMessage = 'Server Error'
            }

            error_page_parameter = "errorcode=" + error.response.status + "&errormessage=" + ErrorMessage;
            window.location = url_parameter_encryption(error_url, error_page_parameter);
            toast.error(ErrorMessage, 'Error Code:' + error.response.status);
        }
    }
}

// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    (error) => {
        errorResponseHandler(error);
    }
);

export default errorResponseHandler;
