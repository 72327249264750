const menuItems = {
    items: [
        {
            id: 'navigation',
            languageKey: 'COMMON.Navigation',
            title: 'Navigation',
            type: 'group',
            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'home',
                    languageKey: 'COMMON.Home',
                    title: 'Home',
                    type: 'collapse',
                    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                    screenID: '',
                    icon: 'feather icon-home',
                    children: [
                        //{
                        //    id: 'default',
                        //    languageKey: 'COMMON.Default',
                        //    title: 'Default',
                        //    type: 'item',
                        //    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                        //    screenID: '5f1b9fc1-57cc-45be-9da7-fc00b87e1348',
                        //    url: '/app/home/dashboard'
                        //},
                        //{
                        //    id: 'tasks_board',
                        //    languageKey: 'COMMON.Task Board',
                        //    title: 'Task Board',
                        //    type: 'item',
                        //    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                        //    screenID: '3710b7ef-182b-4aac-9f1c-4a9a2a771e60',
                        //    url: '/app/home/task_board'
                        //},
                        {
                            id: 'calendar',
                            languageKey: 'COMMON.Calendar',
                            title: 'Calendar',
                            type: 'item',
                            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                            screenID: '1202acfe-83c3-443e-add1-d206d767c6e6',
                            url: '/app/home/calendar'
                        }
                    ]
                }
            ]
        },
        {
            id: 'kri_admin',
            languageKey: 'COMMON.KRI Admin',
            title: 'KRI Admin',
            type: 'group',
            applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'kri_admin',
                    languageKey: 'COMMON.KRI Admin',
                    title: 'KRI Admin',
                    type: 'collapse',
                    applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        //{
                        //    id: 'scorecard_management',
                        //    languageKey: 'KRIADMIN.Scorecard Management',
                        //    title: 'Scorecard Management',
                        //    type: 'item',
                        //    applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                        //    screenID: '55935315-52b6-4055-9af6-4b4bfe64a4ef',
                        //    url: '/app/kri_admin/scorecard_management/scorecard_listing'
                        //},
                        {
                            id: 'library_management',
                            languageKey: 'KRIADMIN.Library Management',
                            title: 'Library Management',
                            type: 'item',
                            applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                            screenID: '6fb423ff-8ff2-47e1-a97d-e111da43f2ed',
                            url: '/app/kri_admin/library_management'
                        }
                    ]
                }
            ]
        },
        {
            id: 'kri_user',
            languageKey: 'COMMON.KRI',
            title: 'KRI',
            type: 'group',
            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'kri_user',
                    languageKey: 'COMMON.KRI',
                    title: 'KRI',
                    type: 'collapse',
                    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'kri',
                            languageKey: 'KRI.Key Risk Indicator',
                            title: 'Key Risk Indicator',
                            type: 'item',
                            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                            screenID: '',
                            url: '/app/kri_user/key_risk_indicator'
                        }
                    ]
                }
            ]
        },
        {
            id: 'report',
            languageKey: 'COMMON.Report',
            title: 'Report',
            type: 'group',
            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'report',
                    languageKey: 'COMMON.Report',
                    title: 'Report',
                    type: 'collapse',
                    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'report',
                            languageKey: 'COMMON.Report',
                            title: 'Report',
                            type: 'item',
                            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                            screenID: '9669d4bd-cf6b-41c8-b4d8-488dd661bb8c',
                            url: '/app/kri_report/report'
                        },
                        {
                            id: 'report_query',
                            languageKey: 'COMMON.Report Query',
                            title: 'Report Query',
                            type: 'item',
                            applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                            screenID: '851437f5-9edb-4a5b-9d48-a22bfba3ed68',
                            url: '/app/kri_report/report_query'
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
