import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";
import AccessRightsGuard from "./components/Auth/AccessRightsGuard";

import CustomSecurityEncrypt from "./components/Security/CustomSecurityEncrypt";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    <CustomSecurityEncrypt {...props}>
                                        {route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props}
                                                applicationID={route.applicationID}
                                                screenID={route.screenID} />}
                                    </CustomSecurityEncrypt>
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Auth/Signin/SignIn'))
    },
    {
        exact: true,
        path: '/404',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Errors/NotFound404'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/dashboard',
            //    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
            //    screenID: '5f1b9fc1-57cc-45be-9da7-fc00b87e1348',
            //    component: lazy(() => import('./views/Home/Dashboard/DashDefault'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/user_profile',
                applicationID: '',
                screenID: '',
                component: lazy(() => import('./views/Common/User_Profile'))
            },
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/task_board',
            //    applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
            //    screenID: '3710b7ef-182b-4aac-9f1c-4a9a2a771e60',
            //    component: lazy(() => import('./views/Home/Task_Board/TaskBoard'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/calendar',
                applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                screenID: '1202acfe-83c3-443e-add1-d206d767c6e6',
                component: lazy(() => import('./views/Home/Calendar/Calendar'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_admin/library_management',
                applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                screenID: '6fb423ff-8ff2-47e1-a97d-e111da43f2ed',
                component: lazy(() => import('./views/KRI/KRI_Admin/Library_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_user/key_risk_indicator',
                applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                screenID: '',
                component: lazy(() => import('./views/KRI/KRI_User/KRI'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_user/key_risk_indicator_form',
                applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                screenID: '',
                component: lazy(() => import('./views/KRI/KRI_User/KRI_Form'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_report/report',
                applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                screenID: '9669d4bd-cf6b-41c8-b4d8-488dd661bb8c',
                component: lazy(() => import('./views/KRI/KRI_Report/Report'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_report/report_query',
                applicationID: '369ba5b6-9b2a-45a1-bca8-83bbc1c08964',
                screenID: '851437f5-9edb-4a5b-9d48-a22bfba3ed68',
                component: lazy(() => import('./views/KRI/KRI_Report/Report_Query'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_admin/scorecard_management/scorecard_listing',
                applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                screenID: '55935315-52b6-4055-9af6-4b4bfe64a4ef',
                component: lazy(() => import('./views/KRI/KRI_Admin/Scorecard_Management/Scorecard_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/kri_admin/scorecard_management/scorecard_details',
                applicationID: 'fb8b73a6-6612-41c6-81f5-5b1b12978856',
                screenID: '0745fb60-22d0-41e4-b460-a4ee724794e3',
                component: lazy(() => import('./views/KRI/KRI_Admin/Scorecard_Management/Scorecard_Details'))
            },
            {
                path: '*',
                exact: true,
                applicationID: '',
                screenID: '',
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
